import Script from 'next/script';

import NormalRoute from '@/components/NormalRoute';
import PrivateRoute from '@/components/PrivateRoute';
import PublicRoute from '@/components/PublicRoute';
import '@/styles/globals.css';
import DefaultTheme from '@/themes';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Next13ProgressBar } from 'next13-progressbar';
import { ReactElement, ReactNode, lazy, useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import nextI18NextConfig from '../../next-i18next.config';

const clientSideEmotionCache = () => createCache({ key: 'css', prepend: true });

const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

type NextPageCustom = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
  type?: 'private' | 'public';
};

type AppPropsCustom = AppProps & {
  Component: NextPageCustom;
};

const ReactQueryDevtoolsDevelopment = lazy(() =>
  import('react-query/devtools/development').then(d => ({
    default: d.ReactQueryDevtools,
  })),
);
function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsCustom) {
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);

  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          retryOnMount: false,
          refetchOnWindowFocus: false,
        },
      },
    }),
  );

  return (
    <>
      <Head>
        <title>Hoi An GO - E-Taxi & Tour Booking</title>
        <link
          rel="icon"
          href='/fav/favicon.ico'
        ></link>
        <meta name="theme-color" content="#d0872a" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <CacheProvider value={clientSideEmotionCache()}>
            <ThemeProvider theme={DefaultTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                {Component.type === 'private' && (
                  <PrivateRoute>
                    {getLayout(<Component {...pageProps} />)}
                  </PrivateRoute>
                )}
                {Component.type === 'public' && (
                  <PublicRoute>
                    {getLayout(<Component {...pageProps} />)}
                  </PublicRoute>
                )}
                {!Component.type && (
                  <NormalRoute>
                    {getLayout(<Component {...pageProps} />)}
                  </NormalRoute>
                )}
                <Next13ProgressBar
                  height="4px"
                  color={DefaultTheme.palette.primary.main}
                  options={{ showSpinner: false }}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </CacheProvider>
        </Hydrate>

        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtoolsDevelopment
            initialIsOpen={false}
            position="bottom-right"
          />
        )}
      </QueryClientProvider>
      <Script id="facebook-pixel">
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${FB_PIXEL_ID}');
        fbq('track', 'PageView');
      `}
      </Script>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ "display": "none" }}
          alt="facebook-pixel"
          src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  );
}

export default appWithTranslation(App, nextI18NextConfig);
