import { lens } from '@dhmk/zustand-lens';
import { GlobalSlice, GlobalState } from './type';

const initialState: GlobalState = {
  accessToken: undefined,
  user: null,
  languages: [],
  languageId: '',
  maxAdults: 0,
  maxChildren: 0,
  hotline: '1900 996690',
  dialCodes: [],
  socketBookingMessage: null,
};

export const createGlobalSlice = lens<GlobalSlice>(set => {
  return {
    ...initialState,

    setAccessToken: token => {
      set(state => {
        state.accessToken = token;
      });
    },

    setUser: data => {
      set(state => {
        state.user = data;
      });
    },

    setLanguages(data) {
      set(state => {
        state.languages = data;
      });
    },

    setLanguageId(id) {
      set(state => {
        state.languageId = id;
      });
    },

    setMaxAdults(value) {
      set(state => {
        state.maxAdults = value;
      });
    },

    setMaxChildren(value) {
      set(state => {
        state.maxChildren = value;
      });
    },

    setHotline(value) {
      set(state => {
        state.hotline = value;
      });
    },

    setDialCodes(data) {
      set(state => {
        state.dialCodes = data;
      });
    },

    setSocketBookingMessage(message) {
      set(state => {
        state.socketBookingMessage = message;
      });
    },
  };
});
